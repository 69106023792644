@charset "utf-8";
* {
  -webkit-text-size-adjust: none;
  word-break: break-all;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: middle;
  appearance: none;
  resize: none;
}
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
body,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
p,
blockquote,
pre,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
figure,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
a,
textarea,
input,
select,
option,
button,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  box-sizing: border-box;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
a:link {
  text-decoration: none;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
b {
  font-weight: 700;
}
strong {
  font-weight: 400;
  vertical-align: middle;
}
hr {
  display: none;
  box-sizing: content-box;
  height: 0;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: 400;
}
abbr,
acronym,
img,
button {
  border: 0;
}
pre {
  white-space: pre-wrap;
}
q {
  quotes: "\201C""\201D""\2018""\2019";
}
sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
span,
em {
  vertical-align: top;
}
ul,
ol,
li {
  list-style: none;
}
figure {
  margin: 0;
}
button {
  cursor: pointer;
}
button,
input,
select,
textarea {
  border: 0 none;
  background-color: #fff;
}
button {
  background-color: transparent;
}
textarea {
  vertical-align: top;
  border: solid 1px #ccc;
}
table {
  border-collapse: collapse;
  empty-cells: show;
  border-spacing: 0;
  table-layout: fixed;
}
table caption,
fieldset legend {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  text-indent: -9999px;
}
abbr[title] {
  border-bottom: 1px dotted;
}
.ta-r,
.right {
  text-align: right !important;
}
.ta-l,
.left {
  text-align: left !important;
}
.ta-c,
.center {
  text-align: center !important;
}
.va-t {
  vertical-align: top !important;
}
.va-m {
  vertical-align: middle !important;
}
.va-b {
  vertical-align: bottom !important;
}
.fl-l {
  display: inline-block;
  float: left !important;
  vertical-align: middle;
}
.fl-r {
  display: inline-block;
  float: right !important;
  vertical-align: middle;
}
.over_-h {
  overflow: hidden;
  width: 100%;
}
.clear {
  clear: both !important;
}
.clear-l {
  clear: left !important;
}
.clear-r {
  clear: right !important;
}
.posi-a {
  position: absolute !important;
}
.posi-r {
  position: relative !important;
}
.block {
  display: block !important;
}
.inline {
  display: inline !important;
}
.normal {
  font-weight: 400 !important;
}
.bold {
  font-weight: 700 !important;
}
.blind {
  visibility: hidden;
  font-size: 0;
  text-indent: -20000px;
}
.none {
  display: none !important;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 120%;
}
.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.break {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.hbox {
  display: flex;
  flex-direction: row;
}
.vbox {
  display: flex;
  flex-direction: column;
}
.hbox > *,
.vbox > * {
  display: block;
}
.ss {
  align-items: start;
  justify-content: start;
}
.sc {
  justify-content: start;
  align-items: center;
}
.se {
  justify-content: start;
  align-items: end;
}
.cs {
  justify-content: center;
  align-items: start;
}
.cc {
  justify-content: center;
  align-items: center;
}
.ce {
  justify-content: center;
  align-items: end;
}
.es {
  justify-content: end;
  align-items: start;
}
.ec {
  justify-content: end;
  align-items: center;
}
.ee {
  justify-content: end;
  align-items: end;
}
.js {
  justify-content: space-between;
  align-items: start;
}
.jc {
  justify-content: space-between;
  align-items: center;
}
.je {
  justify-content: space-between;
  align-items: end;
}
.stretch {
  justify-content: space-between;
  align-items: stretch;
}
.flex > * {
  flex: 1;
}
.flex {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex4 {
  flex: 4;
}
.flex5 {
  flex: 5;
}
.flex6 {
  flex: 6;
}
.w100p,
.full {
  width: 100% !important;
}
.h100p {
  height: 100% !important;
}
::-webkit-input-placeholder {
  color: #666;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

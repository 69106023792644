@charset "utf-8";
/* uracle pjt-team */
@import "font.css";
@import "reset.min.css";

/*
  CSS order:
  1. clear/display/position/z-index/overflow/float
  2. width/height
  3. margin/padding
  4. color/font/line-height/text/letter-spacing/vertical-align/white-spacing/word-spacing
  5. etc
  6. border/background
*/

/*===  COMMON ===*/
html {
  font-size: 62.5%;
} /* rem 기준 폰트 사이즈 */
@media only screen and (max-width: 320px) {
  html {
    font-size: 56.3%;
  }
}
body {
  overflow: hidden;
  position: relative;
  min-height: 100%;
  color: #444;
  font-family: "NotoSansCJKkr", sans-serif;
  background: #ffffff;
}
* {
  word-break: break-word;
  color: #444;
  font-size: 1.5rem;
  font-family: "NotoSansCJKkr", sans-serif;
  line-height: 1.2;
  font-weight: normal;
  letter-spacing: -0.6px;
}
div,
p,
strong,
span,
em,
section,
button {
  -webkit-touch-callout: none;
  user-select: none;
}
object,
img {
  font-size: 0;
  width: 100%;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translate3d(0, 0, 0);
  filter: blur(0px);
}
strong {
  vertical-align: baseline;
}
.over_-h {
  overflow: hidden !important;
}
.kbfont {
  font-family: "KB" !important;
}
.kbfont2 {
  font-family: "KBDisplay" !important;
}
.mt20 {
  margin-top: 2rem !important;
}
.type-h3 {
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem;
}

/*LAYOUT*/
body,
.wrapper {
  height: 100%;
}
.wrapper:before {
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
}
header {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 5.4rem;
  min-height: 5.6rem;
  background: #fff;
}
header h1 {
  height: 5.6rem;
  color: #000;
  font-family: "KB";
  font-size: 2rem;
  line-height: 5.6rem;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
header .btn-head {
  position: absolute;
  bottom: 0;
  width: 5.4rem;
  height: 5.6rem;
  font-size: 0;
  text-indent: -999rem;
}
.btn-head.back {
  left: 0;
  background: url("../img/ic-ic-tit-back.svg") no-repeat right center;
  background-size: 3rem auto;
}
.btn-head.close {
  width: 5rem;
  right: 0;
  background: url("../img/ic-ic-tit-close.svg") no-repeat center center;
  background-size: 3rem auto;
}

footer {
  position: relative;
  z-index: 1;
  width: 100%;
}
.footer > .btn-wrap:only-child {
  padding: 2rem 2.4rem 3rem;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.wrapper > * {
  position: relative;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  z-index: 1;
  overflow: auto;
}
.content-wrap > * {
  display: block;
}
.content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 2.4rem 3rem;
}
.content-wrap > .scrollBox {
  flex: 1;
  height: 100% !important;
}
.scrollBox {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.scrollBox > .vbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*INPUT*/
.ipt-chk,
.ipt-rdo {
  position: relative;
  padding: 0.6rem 0;
  padding-left: 2.6rem;
  text-align: left;
  min-height: 3.4rem;
}
.ipt-chk input[type="checkbox"],
.ipt-rdo input[type="radio"] {
  position: absolute;
  left: 0;
  top: 0.3rem;
  width: 2.6rem;
  height: 2.6rem;
  background: url("../img/btn-checkbox01.svg") no-repeat;
  background-size: 100% auto;
  transition: 0.3s background;
}
.ipt-rdo input[type="radio"] {
  background-image: url("../img/btn-radio.svg");
}
.ipt-chk input[type="checkbox"] + label,
.ipt-rdo input[type="radio"] + label {
  display: inline-block;
  margin-left: 0.8rem;
  font-size: 1.3rem;
  color: #666;
  line-height: 1.38;
}
.ipt-chk input[type="checkbox"]:checked {
  background-image: url("../img/btn-checkbox-active01.svg");
}
.ipt-rdo input[type="radio"]:checked {
  background-image: url("../img/btn-radio-active.svg");
}
.ipt-chk input[type="checkbox"]:disabled {
  background-image: url("../img/btn-checkbox-dis01.svg");
}
.ipt-chk input[type="checkbox"]:checked:disabled {
  background-image: url("../img/btn-checkbox-dis01.svg");
}
.ipt-rdo input[type="radio"]:disabled {
  background-image: url("../img/btn-radio-dis.svg");
}
.ipt-rdo input[type="radio"]:checked:disabled {
  background-image: url("../img/btn-radio-dis.svg");
}
.ipt-chk input[type="checkbox"]:disabled + label,
.ipt-chk input[type="radio"]:disabled + label {
  color: #999;
}

/*BUTTON*/
.btn-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrap * {
  display: block;
  flex: 1;
  width: 100%;
}
.btn-wrap * + * {
  margin-left: 0.6rem;
}
.btn-txt {
  position: relative;
  height: 5.6rem;
  padding: 0 2rem;
  color: #000;
  font-family: "KBDisplay";
  font-size: 1.7rem;
  background: #ffd700;
  border-radius: 2.6rem;
  overflow: hidden;
  letter-spacing: -1px;
}
.btn-txt.gray {
  background-color: #eee;
  color: #1d1c19;
}
.btn-txt:active {
  color: #666;
  position: relative;
  overflow: hidden;
}
.btn-txt:active:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.btn-txt:disabled {
  cursor: initial;
  color: #999 !important;
  background-color: #e1e1e1;
  background-image: none;
}
.btn-txt:disabled:after {
  content: none !important;
}
.btn-sub {
  height: 4rem;
  padding: 0 2.4rem;
  color: #000;
  border-radius: 3.1rem;
}
.btn-sub.point {
  background: #ffd700;
  border: none;
}
.btn-link {
  padding-top: 2rem;
  color: #999;
  font-size: 1.4rem;
  line-height: 1;
  text-decoration: underline;
  text-decoration-line: underline;
}
.btn-pg-close {
  position: fixed;
  top: calc(0rem + env(safe-area-inset-top));
  right: 0;
  width: 5rem;
  height: 5rem;
  background: url("../img/ic-ic-tit-close-w.svg") center center / 3rem auto no-repeat;
  z-index: 1;
}

/*TEXT*/
.txt-blind {
  position: absolute;
  overflow: hidden;
  border: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}
.tip-recom {
  min-height: 1.8rem;
  margin-top: 3rem;
  padding-left: 2.8rem;
  font-size: 1.3rem;
  color: var(--text);
  line-height: 1.8rem;
  font-weight: 100;
  font-family: "KBDisplay";
  letter-spacing: -0.5px !important;
  background: url("../img/nicon-info.png") no-repeat 0 0;
  background-size: 1.8rem auto;
  vertical-align: baseline;
  text-align: left;
}
.info-title {
  color: #000;
  font-size: 2rem;
  line-height: 1.3;
  text-align: center;
  font-weight: 700;
}

/*POPUP*/
.popup-wrap {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.popup-wrap.show {
  display: block;
}
.popup-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.popup {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.popup .popup-box {
  position: relative;
  display: block;
  width: 100%;
  padding: 3rem 2.4rem;
  background: #fff;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.popup .popup-box {
  padding-bottom: calc(2.4rem + constant(safe-area-inset-bottom)) !important;
  padding-bottom: calc(2.4rem + env(safe-area-inset-bottom)) !important;
}
.popup .pop-body {
  min-height: 13.8rem;
}

.popup.popup-mid {
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  text-align: center;
}
.popup.popup-mid .popup-box {
  padding: 3rem 2.6rem 2rem;
  border-radius: 1.6rem;
  padding-bottom: 2rem !important;
}
.popup.popup-mid .pop-body {
  width: 100%;
  min-height: 5.2rem;
  text-align: center;
  color: var(--tpoint);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.popup.popup-mid .pop-body > * {
  display: block;
  line-height: 1.5;
  font-size: 1.6rem;
  color: var(--gray);
}
.popup.popup-mid .pop-body .bold {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: top;
}
.popup.popup-mid .pop-body .sub {
  margin-top: 1.2rem;
}
.popup.popup-mid .pop-body + .pop-foot {
  margin-top: 2rem;
}

.pop-head {
  padding-bottom: 1rem;
  color: #000;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.33;
}
.pop-head .title {
  color: #222;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: -1px;
}
.pop-head * {
  vertical-align: baseline;
}
.pop-head .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 5rem;
  height: 5rem;
  font-size: 0;
  text-indent: -999rem;
  background: url("../img/btn-ic-pop-close.svg") no-repeat left bottom;
  background-size: 2.6rem auto;
}

.popup .btn-txt {
  box-shadow: none;
  padding: 0 1.5rem;
  font-size: 1.6rem;
  font-family: "KBDisplay";
}
.popup.popup-mid .btn-txt {
  height: 5.2rem !important;
}
.popup.popup-btm .btn-wrap:only-child .btn-txt {
  height: 5.6rem !important;
}

.show .popup-mid .popup-box {
  animation-name: zoomIn;
}
.show .popup-btm .popup-box {
  animation-name: fadeInUp;
}
.popup-mid .popup-box,
.popup-btm .popup-box {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
.close .popup-mid .popup-box {
  animation-name: zoomOut;
}
.close .popup-btm .popup-box {
  animation-name: slideOutDown;
}
/* popup animation*/
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}
.zoomIn {
  animation-name: zoomIn;
}
.zoomOut {
  animation-name: zoomOut;
}
.fadeInUp {
  animation-name: fadeInUp;
}
.fadeOutDown {
  animation-name: fadeOutDown;
}
.slideOutDown,
.layerclose {
  animation-name: slideOutDown;
}

/*===  STYLE ===*/
/*NEVE_6_1V*/
.event-box {
  position: relative;
  background: #08284f;
  margin: -2rem -2.4rem -3rem;
}
.event-box .btn-txt {
  height: 4.6rem;
  padding: 0 1.5rem;
  border-radius: 2.4rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  background-image: linear-gradient(to bottom, #5276d1 0%, #2c3a71 100%);
  font-family: "NotoSans";
}
.sub-event .btn-wrap {
  margin-top: -6.5rem;
}
.btn-wrap.block {
  text-align: center;
}
.btn-wrap.block * {
  display: inline-block;
}
.mental-land + .btn-wrap {
  padding: 4rem 4.9rem 6rem;
}
@media screen and (max-width: 320px) {
  .sub-event .btn-wrap {
    margin-top: -4.5rem;
  }
  .mental-land + .btn-wrap {
    padding: 2.2rem 2.4rem 6rem;
  }
}
.btn-review {
  width: auto;
  height: 2.2rem;
  padding: 0 2rem 0 1rem;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.1rem;
  font-family: "KBDisplay";
  border-radius: 1.1rem;
  border: 1px solid #fff;
  opacity: 0.6;
  background: url("../img/btn-review.png") no-repeat right 0.8rem center;
  background-size: 1.1rem auto;
}
.btn-eventbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  text-indent: -999rem;
  background: transparent;
}

.typing-box {
  display: none;
  height: 5rem;
}
.typing-box.on {
  display: block;
}
.typing-box .typing {
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid transparent;
  color: #f7f7f7;
}
.typing.start {
  color: #000;
  animation: typing 1.5s steps(25), blink 0.5s step-end infinite alternate;
}
.typing.start.small {
  animation: typing 0.5s steps(25), blink 0.5s step-end infinite alternate;
}
.typing.start.middle {
  animation: typing 1s steps(25), blink 0.5s step-end infinite alternate;
}
.typing-box .typing.end {
  color: #000;
  animation: none;
}
.typing-box.typing1.on .typing.ani1.start {
  animation-delay: 0.3s !important;
}
@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: black;
  }
}

.mental-land {
  position: relative;
  width: 36rem;
  height: 76.3rem;
  margin: 0 auto 4.7rem;
}
.mental-land .bgicon {
  position: absolute;
}
.bgicon.cloud1 {
  width: 4.6rem;
  height: 4.6rem;
  right: 2.5rem;
  top: 0;
}
.bgicon.cloud2 {
  width: 3.4rem;
  height: 3.4rem;
  top: 13.3rem;
  left: 3.4rem;
}
.bgicon.cloud3 {
  width: 6.6rem;
  height: 6.6rem;
  right: 1.9rem;
  bottom: 7.5rem;
}
.bgicon.cloud {
  background: url("../img/img-cloud.png") no-repeat center center;
  background-size: 100% auto;
}
.bgicon.ship {
  top: 32.8rem;
  left: 4.9rem;
  width: 4.4rem;
  height: 4.4rem;
  background: url("../img/img-ship.png") no-repeat center center;
  background-size: 100% auto;
}
.bgicon.ballon {
  top: 41.6rem;
  right: 2.2rem;
  width: 4.4rem;
  height: 4.4rem;
  background: url("../img/img-ballon.png") no-repeat center center;
  background-size: 100% auto;
}

.mental-land .land {
  position: absolute;
  display: inline-block;
  width: 50%;
  height: 10rem;
  text-align: right;
}
.land.month12 {
  top: 0.4rem;
  left: 6rem;
  width: 16.6rem;
  height: 16.6rem;
  background-image: url("../img/island-off-1.png");
}
.land.month1 {
  top: 6.2rem;
  right: 3.5rem;
  width: 17rem;
  height: 20rem;
  background-image: url("../img/island-off-2.png");
}
.land.month2 {
  top: 17.5rem;
  left: 2.3rem;
  width: 18.6rem;
  height: 16rem;
  background-image: url("../img/island-off-3.png");
}
.land.month3 {
  top: 23.3rem;
  right: 4.4rem;
  width: 16rem;
  height: 21rem;
  background-image: url("../img/island-off-4.png");
}
.land.month4 {
  top: 37.1rem;
  left: 3.4rem;
  width: 17rem;
  height: 19rem;
  background-image: url("../img/island-off-5.png");
}
.land.month5 {
  top: 43.7rem;
  right: 3.2rem;
  width: 13.4rem;
  height: 17.4rem;
  background-image: url("../img/island-off-6.png");
}
.land.month6 {
  top: 52.3rem;
  left: 6.1rem;
  width: 22rem;
  height: 20rem;
  background-image: url("../img/island-off-7.png");
}
.land.month12.on,
.land.month12.clear {
  background-image: url("../img/island-on-1.png");
}
.land.month1.on,
.land.month1.clear {
  background-image: url("../img/island-on-2.png");
}
.land.month2.on,
.land.month2.clear {
  background-image: url("../img/island-on-3.png");
}
.land.month3.on,
.land.month3.clear {
  background-image: url("../img/island-on-4.png");
}
.land.month4.on,
.land.month4.clear {
  background-image: url("../img/island-on-5.png");
}
.land.month5.on,
.land.month5.clear {
  background-image: url("../img/island-on-6.png");
}
.land.month6.on,
.land.month6.clear {
  background-image: url("../img/island-on-7.png");
}

.mental-land .land {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
}
@keyframes land1 {
  0%,
  100% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-8px) rotate(0);
  }
}

.mental-land .land.on:after,
.mental-land .land.clear:after {
  content: "";
  position: absolute;
}
/* .mental-land .land .btn-land{display:none;} */
.mental-land .land .btn-land {
  display: block;
  position: absolute;
  font-size: 0;
  text-indent: -999rem;
  pointer-events: none;
}
.mental-land .land.on .btn-land,
.mental-land .land.clear .btn-land {
  pointer-events: visible;
  z-index: 1;
}
.month12 .btn-land {
  left: 2.2rem;
  top: 2.3rem;
  width: 80%;
  height: 80%;
}
.month1 .btn-land {
  left: 2.2rem;
  bottom: 0.6rem;
  width: 85%;
  height: 66%;
}
.month2 .btn-land {
  left: 2.3rem;
  top: 0;
  width: 80%;
  height: 95%;
}
.month3 .btn-land {
  left: 2.1rem;
  top: 2.5rem;
  width: 80%;
  height: 80%;
}
.month4 .btn-land {
  left: 0;
  top: 2.8rem;
  width: 94%;
  height: 80%;
}
.month5 .btn-land {
  left: 0;
  top: 0;
  width: 99%;
  height: 90%;
}
.month6 .btn-land {
  left: 0;
  top: 3.4rem;
  width: 99%;
  height: 76%;
}

.mental-land .land.clear:after {
  width: 6.6rem;
  height: 6.6rem;
  z-index: 1;
  background: url("../img/img-icon-clear.png") no-repeat center center;
  background-size: 100% auto;
  pointer-events: none;
}
.land.month12.clear:after {
  bottom: 1.9rem;
  left: 4.3rem;
}
.land.month1.clear:after {
  bottom: 1.4rem;
  left: 3.8rem;
}
.land.month2.clear:after {
  bottom: 1.9rem;
  left: 3.2rem;
}
.land.month3.clear:after {
  bottom: 2.4rem;
  left: 5.8rem;
}
.land.month4.clear:after {
  bottom: 1.9rem;
  left: 7rem;
}
.land.month5.clear:after {
  bottom: 1.5rem;
  left: 4.9rem;
}
.land.month6.clear:after {
  bottom: 2.2rem;
  left: 7.8rem;
}

.mental-land .land.on:after {
  width: 6rem;
  height: 4rem;
  z-index: 1;
}
.land.month12.on:after {
  bottom: 2.6rem;
  left: 1.5rem;
  background: url("../img/ready-1.png") no-repeat center center;
  background-size: 100% auto;
}
.land.month1.on:after {
  bottom: 2.8rem;
  left: 4.4rem;
  background: url("../img/ready-2.png") no-repeat center center;
  background-size: 100% auto;
}
.land.month2.on:after {
  bottom: 5rem;
  left: 1.7rem;
  background: url("../img/ready-3.png") no-repeat center center;
  background-size: 100% auto;
}
.land.month3.on:after {
  bottom: 3.2rem;
  left: 6.7rem;
  background: url("../img/ready-4.png") no-repeat center center;
  background-size: 100% auto;
}
.land.month4.on:after {
  bottom: 1.8rem;
  left: 0.9rem;
  background: url("../img/ready-5.png") no-repeat center center;
  background-size: 100% auto;
}
.land.month5.on:after {
  bottom: 0.8rem;
  left: 5.8rem;
  background: url("../img/ready-6.png") no-repeat center center;
  background-size: 100% auto;
}
.land.month6.on:after {
  bottom: 1.9rem;
  left: 5rem;
  background: url("../img/ready-7.png") no-repeat center center;
  background-size: 100% auto;
}

/*poopup*/
.story-popup .pop-body {
  overflow: visible !important;
}
.story-popup .btn-skip {
  position: absolute;
  right: 2.4rem;
  top: -4.5rem;
  color: #fff;
  font-size: 1.3rem;
  height: 2.8rem;
  padding: 0 2.6rem 0 1.1rem;
  border-radius: 1.4rem;
  border: 1px solid #ccc;
  background: url("../img/btn-ic-arrow-more.svg") no-repeat right 0.5rem center;
  background-size: 2rem auto;
  z-index: 1;
}
.story-box {
  position: relative;
  overflow: visible;
}
.story-box:before {
  content: "";
  position: absolute;
}
.story-box.story1:before {
  left: 4.5rem;
  top: -25.7rem;
  width: 20.5rem;
  height: 22.8rem;
  background: url("../img/icon-mentaltest-oh.svg") no-repeat center center;
  background-size: 100% auto;
}
.story-box.story2:before {
  left: 6rem;
  top: -22.7rem;
  width: 15.9rem;
  height: 15.4rem;
  background: url("../img/icon-mentaltest-haru.svg") no-repeat center center;
  background-size: 100% auto;
  animation: land1 5s ease-in-out infinite;
}
.story-box .title {
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.icon-top.mental {
  padding: 12rem 0 3rem;
  background: url("../img/icon-mentaltest-100.svg") no-repeat center top;
  background-size: 10rem auto;
}
.typing-box {
  margin-top: 2rem;
  width: 100%;
  height: 14.2rem;
  padding: 2rem;
  background: #f7f7f7;
  border-radius: 1.6rem;
  text-align: center;
}
.typing-box * {
  text-align: center;
  margin: 0 auto;
  color: #000;
  font-size: 1.6rem;
  line-height: 1.38;
}

.btn-nextstroy {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-top: -6rem;
  z-index: 1;
  position: relative;
  font-size: 0;
  text-indent: -999rem;
  background: url("../img/arrow-down.svg") no-repeat center center;
  background-size: 100% auto;
  animation: opacity 2s infinite;
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacity1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.story2 .typing6 .btn-sub {
  display: none;
}
.btn-sub.showbutton {
  opacity: 1;
  display: block !important;
  animation: opacity2 2s 1;
}
@keyframes opacity2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.story1 .typing1 .typing.ani1 {
  width: 27rem;
}
.story1 .typing1 .typing.ani2 {
  width: 18rem;
}
.story1 .typing1 .typing.ani3 {
  width: 26rem;
}
.story1 .typing2 .typing.ani1 {
  width: 20.5rem;
}
.story1 .typing2 .typing.ani2 {
  width: 11.5rem;
}
.story1 .typing2 .typing.ani3 {
  width: 18.5rem;
}
.story1 .typing3 .typing.ani1 {
  width: 14.5rem;
}
.story1 .typing3 .typing.ani2 {
  width: 24.5rem;
}
.story1 .typing4 .typing.ani1 {
  width: 22rem;
}
.story1 .typing4 .typing.ani2 {
  width: 16rem;
}
.story1 .typing5 .typing.ani1 {
  width: 24.5rem;
}
.story1 .typing6 .typing.ani1 {
  width: 3rem;
}
.story1 .typing6 .typing.ani2 {
  width: 18.5rem;
}
.story1 .typing6 .typing.ani3 {
  width: 13rem;
}

.story2 .typing1 .typing.ani1 {
  width: 16rem;
}
.story2 .typing1 .typing.ani2 {
  width: 15rem;
}
.story2 .typing1 .typing.ani3 {
  width: 20.5rem;
}
.story2 .typing2 .typing.ani1 {
  width: 23rem;
}
.story2 .typing2 .typing.ani2 {
  width: 19rem;
}
.story2 .typing2 .typing.ani3 {
  width: 25rem;
}
.story2 .typing3 .typing.ani1 {
  width: 17rem;
}
.story2 .typing3 .typing.ani2 {
  width: 14rem;
}
.story2 .typing3 .typing.ani3 {
  width: 21rem;
}
.story2 .typing4 .typing.ani1 {
  width: 15rem;
}
.story2 .typing4 .typing.ani2 {
  width: 18rem;
}
.story2 .typing5 .typing.ani1 {
  width: 18.5rem;
}
.story2 .typing5 .typing.ani2 {
  width: 14rem;
}
.story2 .typing5 .typing.ani3 {
  width: 25rem;
}
.story2 .typing6 .typing.ani1 {
  width: 23.5rem;
}
.story2 .typing6 .typing.ani2 {
  width: 13rem;
}

/* fold phone 대응 */
@media screen and (max-width: 320px) {
  .mental-land {
    width: 28rem;
    height: 102.7rem;
    margin: 4.7rem auto;
  }

  .land.month12 {
    top: 2rem;
    left: 2.4rem;
  }
  .land.month1 {
    top: 11.2rem;
    right: 2rem;
  }
  .land.month2 {
    top: 28.3rem;
    left: 1.8rem;
  }
  .land.month3 {
    top: 37.8rem;
    right: 1.5rem;
    background-image: url("../img/island-off-4-280.png");
  }
  .land.month4 {
    top: 53.2rem;
    left: 2.8rem;
  }
  .land.month5 {
    top: 71.6rem;
    right: 1.4rem;
  }
  .land.month6 {
    top: 80.8rem;
    left: 2rem;
  }
  .land.month3.on,
  .land.month3.clear {
    background-image: url("../img/island-on-4-280.png");
  }

  .bgicon.cloud1 {
    width: 3.6rem;
    height: 3.6rem;
    right: 1.9rem;
    top: 3.4rem;
  }
  .bgicon.cloud2 {
    width: 3.2rem;
    height: 3.2rem;
    top: 16.7rem;
    left: 3.1rem;
  }
  .bgicon.cloud3 {
    width: 5.2rem;
    height: 5.2rem;
    right: 2.3rem;
    bottom: initial;
    top: 61.1rem;
  }
  .bgicon.ship {
    top: 22.2rem;
    left: 4.7rem;
    width: 3.4rem;
    height: 3.4rem;
  }
  .bgicon.ship2 {
    top: 72.4rem;
    left: 3.6rem;
    width: 7rem;
    height: 7rem;
    background: url("../img/img-ship-2.png") no-repeat center center;
    background-size: 100% auto;
  }
  .bgicon.ballon {
    top: 32.1rem;
    right: 4.5rem;
    width: 3.8rem;
    height: 3.8rem;
  }

  .typing-box {
    padding: 2rem 1.5rem;
  }
  .story-box.story1:before {
    left: 1rem;
  }
  .story-box.story2:before {
    left: 2rem;
  }
  .typing-box * {
    font-size: 1.4rem;
  }

  .story1 .typing1 .typing.ani1 {
    width: 23rem;
  }
  .story1 .typing1 .typing.ani2 {
    width: 16.5rem;
  }
  .story1 .typing1 .typing.ani3 {
    width: 22.5rem;
  }
  .story1 .typing2 .typing.ani1 {
    width: 18.5rem;
  }
  .story1 .typing2 .typing.ani2 {
    width: 9.5rem;
  }
  .story1 .typing2 .typing.ani3 {
    width: 16.5rem;
  }
  .story1 .typing3 .typing.ani1 {
    width: 12.5rem;
  }
  .story1 .typing3 .typing.ani2 {
    width: 22rem;
  }
  .story1 .typing4 .typing.ani1 {
    width: 20rem;
  }
  .story1 .typing4 .typing.ani2 {
    width: 14rem;
  }
  .story1 .typing5 .typing.ani1 {
    width: 22.5rem;
  }
  .story1 .typing6 .typing.ani1 {
    width: 2rem;
  }
  .story1 .typing6 .typing.ani2 {
    width: 12rem;
  }
  .story1 .typing6 .typing.ani3 {
    width: 11rem;
  }

  .story2 .typing1 .typing.ani1 {
    width: 14rem;
  }
  .story2 .typing1 .typing.ani2 {
    width: 13rem;
  }
  .story2 .typing1 .typing.ani3 {
    width: 18.5rem;
  }
  .story2 .typing2 .typing.ani1 {
    width: 21rem;
  }
  .story2 .typing2 .typing.ani2 {
    width: 17rem;
  }
  .story2 .typing2 .typing.ani3 {
    width: 23rem;
  }
  .story2 .typing3 .typing.ani1 {
    width: 15rem;
  }
  .story2 .typing3 .typing.ani2 {
    width: 12rem;
  }
  .story2 .typing3 .typing.ani3 {
    width: 19rem;
  }
  .story2 .typing4 .typing.ani1 {
    width: 13rem;
  }
  .story2 .typing4 .typing.ani2 {
    width: 16rem;
  }
  .story2 .typing5 .typing.ani1 {
    width: 16.5rem;
  }
  .story2 .typing5 .typing.ani2 {
    width: 12rem;
  }
  .story2 .typing5 .typing.ani3 {
    width: 23rem;
  }
  .story2 .typing6 .typing.ani1 {
    width: 21.5rem;
  }
  .story2 .typing6 .typing.ani2 {
    width: 11rem;
  }
}
/*NEVE_6_5V*/
.mental-infomation-img {
  display: block;
  width: 15rem;
  height: 15rem;
  margin: 0 auto 1rem;
}
.round-graybox {
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 1.4rem;
  background: #f7f7f7;
}
.round-graybox li + li {
  margin-top: 1rem;
}
.round-graybox li {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.round-graybox li > * {
  display: block;
}
.round-graybox .title {
  flex: 1;
  color: #666 !important;
  font-size: 1.5rem;
  line-height: 2.6rem;
}
.round-graybox .data {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.6rem;
}
.detail-title {
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: #000;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.33;
}
.detail-txt {
  color: #444;
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: -0.6px;
}

/*NEVE_6_6V*/
.mental-test .tip-recom {
  margin-top: 0;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #d7d7d7;
}
.survey-box + .survey-box {
  margin-top: 3rem;
}
.survey-box-title {
  display: block;
  padding-left: 3rem;
  position: relative;
  font-size: 1.8rem;
  font-weight: 500;
  color: #000;
  line-height: 1.33;
}
.survey-box-title strong {
  width: 2.4rem;
  text-align: right;
  font: inherit;
  color: inherit;
  position: absolute;
  left: 0;
  top: 0;
  line-height: inherit;
}
.btn-survey-list {
  margin-top: 3rem;
}
.btn-survey-list p + p {
  margin-top: 1.6rem;
}
.btn-survey-list p {
  position: relative;
  width: 100%;
}
.btn-survey-list p input[type="radio"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
}
.btn-survey-list p input[type="radio"] + label {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: 1.4rem 2.4rem;
  text-align: left;
  border-radius: 2.5rem;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000;
  line-height: 1.25;
}
.btn-survey-list p input[type="radio"]:checked + label {
  padding-right: 4rem;
  border: 1px solid #ffd700;
  box-shadow: 0 0 0 1px #ffd700 inset;
  background: url(../img/ic-ic-check-main.svg) no-repeat right 2rem top 1.8rem;
  background-size: 1.7rem auto;
  background-color: #fff8d9;
}

/*NEVE_6_7P*/
.mental-result-img {
  display: block;
  width: 15rem;
  height: 15rem;
  margin: 1rem auto;
}
.mental-result-txt {
  padding-bottom: 3rem;
  text-align: center;
  border-bottom: 1px solid #d7d7d7;
}
.mental-result-txt .color {
  display: inline-flex;
  align-items: center;
  min-height: 3rem;
  padding: 0.2rem 1rem;
  border-radius: 9rem;
  font-size: 1.8rem;
  font-weight: 700;
  border: 1px solid #ccc;
  color: #000;
}
.mental-result-txt .color.red {
  color: #f45d6f !important;
  border-color: #f45d6f;
}
.mental-result-txt .color.purple {
  color: #d376ff !important;
  border-color: #d376ff;
}
.mental-result-txt .color.blue {
  color: #5dacf4 !important;
  border-color: #5dacf4;
}
.mental-result-txt .color.green {
  color: #bdc015 !important;
  border-color: #bdc015;
}
.mental-result .detail-title {
  margin-bottom: 0;
  color: #444;
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 700;
}

/*NEVE_6_8V*/
.btn-survey-radio {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: space-around;
}
.btn-survey-radio.type-col .ipt-rdo {
  padding: 3.7rem 0.5rem 0;
  min-width: 6rem;
}
.btn-survey-radio.type-col .ipt-rdo input[type="radio"] {
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.btn-survey-radio.type-col .ipt-rdo label {
  width: 100%;
  text-align: center;
  margin-left: 0;
}

/*NEVE_6_9P*/
.mental-result.type02 .mental-result-img {
  margin-top: 2rem;
}
.mental-result.type02 .mental-result-txt {
  border-bottom: 0;
  padding-bottom: 0;
}
.mental-result.type02 .mental-result-txt .color {
  min-height: 3.6rem;
  line-height: 1.33;
  padding: 0.5rem 1.8rem;
}
.mental-result.type02 .detail-title {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
}

/*=== MEDIA QUERYS ===*/
/*갤럭시폴드 클로즈형*/
@media only screen and (max-width: 320px) {
  html {
    font-size: 56.3%;
  }
  header {
    padding: 0 4rem;
  }
  header .btn-head {
    width: 4rem !important;
  }
}

/*=== (로딩) ===*/
.loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.loading-wrap .loading-bar {
  position: relative;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 8.2rem;
  height: 8.7rem;
}
.loading-wrap .loading-bar {
  background: url(../img/ic-loading-solid.svg) no-repeat center center;
  background-size: 6.2rem auto;
}
.loading-wrap .loading-bar::before {
  content: "";
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 6.2rem;
  height: 6.7rem;
  background: url(../img/ic-loading-opacity.svg) no-repeat center center;
  background-size: 100% auto;
  animation: loadingmove 1.2s ease infinite;
}

@keyframes loadingmove {
  50% {
    left: 2rem;
    top: 2rem;
  }
  100% {
    left: 0rem;
    top: 0rem;
  }
}

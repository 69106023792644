
/*기본 Noto Sans*/
@font-face { 
	font-family: 'NotoSansCJKkr'; 
	font-style: normal; 
	font-weight: 500; 
	src: local(""), 
		url("fonts/notosanscjk/NotoSansCJKkr-Medium.woff2") format("woff2"), 
		url("fonts/notosanscjk/NotoSansCJKkr-Medium.woff") format("woff");
}
@font-face { 
	font-family: 'NotoSansCJKkr'; 
	font-style: normal; 
	font-weight: normal; 
	src: local(""), 
		url("fonts/notosanscjk/NotoSansCJKkr-Regular.woff2") format("woff2"), 
		url("fonts/notosanscjk/NotoSansCJKkr-Regular.woff") format("woff");
}
@font-face { 
	font-family: 'NotoSansCJKkr'; 
	font-style: normal; 
	font-weight: 700; 
	src: local(""), 
		url("fonts/notosanscjk/NotoSansCJKkr-Bold.woff2") format("woff2"), 
		url("fonts/notosanscjk/NotoSansCJKkr-Bold.woff") format("woff");
}

/*숫자만 사용하는 Open Sans*/
@font-face { 
	font-family: 'Open Sans'; 
	font-style: normal; 
	src: local(""), 
		url("fonts/opensans/OpenSans-Regular.woff2") format("woff2"), 
		url("fonts/opensans/OpenSans-Regular.woff") format("woff");  
}
@font-face { 
	font-family: 'Open Sans'; 
	font-weight: 700; 
	src: local(""), 
		url("fonts/opensans/OpenSans-Bold.woff2") format("woff2"), 
		url("fonts/opensans/OpenSans-Bold.woff") format("woff");  
}
@font-face { 
	font-family: 'Open Sans'; 
	font-weight: 700; 
	font-style: italic; 
	src: local(""), 
		url("fonts/opensans/OpenSans-BoldItalic.woff2") format("woff2"), 
		url("fonts/opensans/OpenSans-BoldItalic.woff") format("woff");  
}

/*KB FONT*/
@font-face { 
	font-family: 'KB'; 
	font-style: normal; 
	font-weight: normal; 
	src: local(""), 
		url("fonts/kbfg/KBFGDISPLAYB.woff2") format("woff2"),
		url("fonts/kbfg/KBFGDISPLAYB.woff") format("woff");
}

/*MVP2-2 KB 폰트 추가 */
@font-face { 
	font-family: 'KBDisplay'; 
	font-style: normal; 
	font-weight: normal; 
	src: local(""), 
		url("fonts/kbfg/KBFGDISPLAYM.woff2") format("woff2"),
		url("fonts/kbfg/KBFGDISPLAYM.woff") format("woff");
}
@font-face { 
	font-family: 'KBDisplay'; 
	font-style: normal; 
	font-weight: 300; 
	src: local(""), 
		url("fonts/kbfg/KBFGDISPLAYL.woff2") format("woff2"),
		url("fonts/kbfg/KBFGDISPLAYL.woff") format("woff");
}
/*@font-face { 
	font-family: 'KBDisplay'; 
	font-style: normal; 
	font-weight: 700; 
	src: local(""), 
		url("fonts/kbfg/KBFGDISPLAYB.woff2") format("woff2"),
		url("fonts/kbfg/KBFGDISPLAYB.woff") format("woff"); 
		
}*/
@font-face { 
	font-family: 'KBDisplayMI'; 
	font-style: normal; 
	font-weight: normal; 
	src: local(""), 
		url("fonts/kbfg/KBFGDISPLAYMI.woff2") format("woff2"),
		url("fonts/kbfg/KBFGDISPLAYMI.woff") format("woff");
}